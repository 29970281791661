export const Config = {
  REACT_APP_API_KEY:
    process.env.REACT_APP_API_KEY || window._env_.REACT_APP_API_KEY,
  REACT_APP_CLIENT_ID:
    process.env.REACT_APP_CLIENT_ID || window._env_.REACT_APP_CLIENT_ID,
  REACT_APP_API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL || window._env_.REACT_APP_API_BASE_URL,
  REACT_APP_API_HASURA_BASE_URL:
    process.env.REACT_APP_API_HASURA_BASE_URL ||
    window._env_.REACT_APP_API_HASURA_BASE_URL,
  REACT_APP_API_SH_GEOGRAPHY_EVENT_ORIGIN:
    process.env.REACT_APP_API_SH_GEOGRAPHY_EVENT_ORIGIN ||
    window._env_.REACT_APP_API_SH_GEOGRAPHY_EVENT_ORIGIN,
  REACT_APP_UNIVERSAL_LOGIN_URL:
    process.env.REACT_APP_UNIVERSAL_LOGIN_URL ||
    window._env_.REACT_APP_UNIVERSAL_LOGIN_URL,
};
