import React, { useMemo } from "react";

import "./loading.component.styles.scss";
// import { RingLoader } from "react-spinners";

const themes = {
  WITW: {
    bg: "#1a94b4",
    textStyle: {
      color: "#f4bc13",
      fontFamily: "Lilita One, Helvetica, sans-serif",
      fontWeight: 400,
    },
  },
};

function Loading({ gameType }) {
  const textStyle = useMemo(
    () => themes[gameType]?.textStyle || {},
    [gameType]
  );
  const background = useMemo(() => themes[gameType]?.bg || "#000", [gameType]);

  return (
    <div className="loading" style={{ background }}>
      {/* <RingLoader color={color} loading={true} size={250} /> */}
      <h2 style={textStyle}>Loading...</h2>
    </div>
  );
}

export default Loading;
