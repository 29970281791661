import instance, {
  InvalidJWTCodeError,
  hasuraInstance,
  refreshToken,
  verifyToken,
} from "./api";
import TokenService from "./tokenService";
import { REFRESH_TOKEN_URL, VERIFY_TOKEN_URL } from "./requestURLs";

const setup = () => {
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;
      if (originalConfig.url !== REFRESH_TOKEN_URL && err.response) {
        // Access Token was expired
        if (
          originalConfig.url !== VERIFY_TOKEN_URL &&
          err.response.status === 401 &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;

          await verifyToken();
          originalConfig.headers.Authorization = `Bearer ${TokenService.getLocalAccessToken()}`;
          const resp = await instance(originalConfig);
          return Promise.resolve(resp);
        }

        if (
          err.response.status === 401 &&
          originalConfig.url === VERIFY_TOKEN_URL &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          await refreshToken();
          originalConfig.headers.Authorization = `Bearer ${TokenService.getLocalAccessToken()}`;
        }
        // return Promise.reject(err);
      }
      return Promise.reject(err);
    }
  );

  hasuraInstance.interceptors.response.use(
    async (res) => {
      const originalConfig = res.config;
      if (
        res.data?.errors?.[0]?.extensions?.code === InvalidJWTCodeError &&
        !originalConfig._retry
      ) {
        //token is expired
        originalConfig._retry = true;

        await verifyToken();
        originalConfig.headers.Authorization = `Bearer ${TokenService.getLocalAccessToken()}`;
        const resp = await hasuraInstance(originalConfig);
        return Promise.resolve(resp);
      }

      return Promise.resolve(res);
    },
    async (err) => err
  );
};

export default setup;
