export const initialState = {
  room2Loaded: false,
  hplLoaded: false,
  hprLoaded: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADED":
      return {
        ...state,
        room2Loaded: true,
      };

    case "SET_HPL_LOADED":
      return {
        ...state,
        room2Loaded: true,
      };

    case "SET_HPR_LOADED":
      return {
        ...state,
        room2Loaded: true,
      };
    default:
      return state;
  }
};

export default reducer;
