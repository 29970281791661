class TokenServiceInstance {
  getLocalAccessToken() {
    return JSON.parse(localStorage.getItem("access_token"));
  }

  getLocalRefreshToken() {
    return JSON.parse(localStorage.getItem("refresh_token"));
  }

  getGameCode() {
    return JSON.parse(localStorage.getItem("GAME_CODE"));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("access_token"));
  }

  updateLocalAccessToken(access_token) {
    localStorage.setItem("access_token", JSON.stringify(access_token));
  }

  setUser({ refresh_token, access_token, event_id, game_type }) {
    localStorage.setItem("refresh_token", JSON.stringify(refresh_token));
    localStorage.setItem("access_token", JSON.stringify(access_token));
    event_id && localStorage.setItem("GAME_CODE", JSON.stringify(event_id));
    game_type && localStorage.setItem("game_type", JSON.stringify(game_type));
  }

  removeUser() {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("GAME_CODE");
    localStorage.removeItem("game_type");
    localStorage.removeItem("ROOM_CODE");
    localStorage.removeItem("PLAYERID");
  }
}

const TokenService = new TokenServiceInstance();

export default TokenService;
