import axios from "axios";
import {
  GET_AUTH_URL,
  GRAPHQL_URL,
  REFRESH_TOKEN_URL,
  VERIFY_TOKEN_URL,
} from "./requestURLs.js";
import TokenService from "./tokenService.js";
import { Config } from "./config.js";

const instance = axios.create({
  baseURL: Config.REACT_APP_API_BASE_URL,
  headers: {},
});

export const hasuraInstance = axios.create({
  baseURL: Config.REACT_APP_API_HASURA_BASE_URL,
  headers: {},
});

export const InvalidJWTCodeError = "invalid-jwt";

export const getAuth = async () => {
  return await instance
    .get(GET_AUTH_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    })
    .then((res) => res.data)
    .catch((e) => {
      console.log(e.message);
      return e;
    });
};

export const refreshToken = async () => {
  return await instance
    .post(REFRESH_TOKEN_URL, {
      refreshToken: TokenService.getLocalRefreshToken(),
      accessToken: TokenService.getLocalAccessToken(),
    })
    .then((res) => {
      TokenService.setUser({
        refresh_token: res.data.refreshToken,
        access_token: res.data.accessToken,
      });
    })
    .catch((e) => {
      console.log(e.message);
      return e;
    });
};

export const verifyToken = async () => {
  return await instance
    .post(
      VERIFY_TOKEN_URL,
      {},
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((e) => {
      console.log(e.message);
      return e;
    });
};

export const getEventInfo = async ({ eventId }) => {
  const graphqlQuery = {
    operationName: "MyQuery",
    query: `query MyQuery ($eventId: uuid!) {
        event: events_by_pk (id: $eventId) {
          id
          status
          date
          gameInformationSelfHosted {
            id
            rounds
            difficulty
          }
          teams {
            id
            name
            players {
              id
              name
            }
          }
          codes {
            code
          }
        }
        locations: witw_getrandomcoordinates {
          id
          latlng
        }
      }`,
    variables: {
      eventId,
    },
  };

  return await hasuraInstance
    .post(GRAPHQL_URL, graphqlQuery, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    })
    .then((res) => {
      if (res.data.errors) {
        throw new Error("Some Request Error");
      }
      return res.data;
    })
    .then((res) => res.data)
    .catch((e) => {
      console.log(e.message);
      return e;
    });
};

export const createAttempt = async (attempt) => {
  const graphqlQuery = {
    operationName: "MyMutation",
    query: `mutation MyMutation ($attempt: witwCoordinateAttempts_insert_input!) {
      insert_witwCoordinateAttempts_one(object: $attempt) {
        id
      }
    }`,
    variables: {
      attempt,
    },
  };

  return await hasuraInstance
    .post(GRAPHQL_URL, graphqlQuery, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    })
    .then((res) => {
      if (res.data.errors) {
        throw new Error("Some Request Error");
      }
      return res.data;
    })
    .then((res) => res.data)
    .catch((e) => {
      console.log(e.message);
      return e;
    });
};

export const updateEventResults = async ({ eventId, totalScore }) => {
  const graphqlQuery = {
    operationName: "MyMutation",
    query: `mutation MyMutation ($eventId: uuid!) {
      update_teams(where: { eventId: { _eq: $eventId}}, _set: { totalScore: ${totalScore}}) {
        returning {
          id
          totalScore
        }
      }
      update_events_by_pk(pk_columns: { id: $eventId}, _set: { status: completed }) {
        id
      }
    }`,
    variables: {
      eventId,
    },
  };

  return await hasuraInstance
    .post(GRAPHQL_URL, graphqlQuery, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${TokenService.getLocalAccessToken()}`,
      },
    })
    .then((res) => {
      if (res.data.errors) {
        throw new Error("Some Request Error");
      }
      return res.data;
    })
    .then((res) => res.data.update_teams)
    .catch((e) => {
      console.log(e.message);
      return e;
    });
};

export default instance;
