import "./App.scss";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Loading from "./components/loading/loading.component";

const TeamWaiting = lazy(() => import("./pages/teamWaiting/teamWaiting.page"));

const ObservationPuzzle = lazy(() => import("./components/observationPuzzle/observationPuzzle.component"));
const WardenGameStart = lazy(() => import("./components/wardenGameStart/wardenGameStart.component"));
const PlayerGameStart = lazy(() => import("./components/playerGameStart/playerGameStart.component"));
const Puzzle1 = lazy(() => import("./components/LogicPuzzles/PuzzleV1/Puzzle"));
const Puzzle2 = lazy(() => import("./components/LogicPuzzles/PuzzleV2/Puzzle"));
const Puzzle3 = lazy(() => import("./components/LogicPuzzles/PuzzleV3/Puzzle"));
const WaitingRoom = lazy(() => import("./components/waitingRoom/waitingRoom.component"));
const ObservationPuzzleInstruction = lazy(() => import("./pages/observationPuzzleInstruction/observationPuzzleInstruction"));
const NextPuzzleChoice = lazy(() => import("./pages/nextPuzzleChoice/nextPuzzleChoice"));
const Room2 = lazy(() => import("./pages/Room2/room2"));
const GameIntro = lazy(() => import("./pages/room1Entry/gameintro.page"));
const RoomOneResult = lazy(() => import("./pages/roomOneResult/roomOneResult.page"));
const Room2Entry = lazy(() => import("./pages/room2Entry/room2Entry.page"));
const RoomTwoResult = lazy(() => import("./pages/roomTwoResult/roomTwoResult.page"));
const Spyislandanimation = lazy(() => import("./pages/spyislandAnimation/spyislandanimation.page"));
const KeypadPuzzle = lazy(() => import("./components/KeypadPuzzle/KeypadPuzzle"));
const GeoGuesserGame = lazy(() => import("./components/Geoguessr/Game"));
const Room3Entry = lazy(() => import("./pages/room3Entry/room3Entry.page"));
const RoomThreeResult = lazy(() => import("./pages/roomThreeResults/roomThreeResult.page"));
const RoomCodeExpired = lazy(() => import("./pages/roomCodeExpired/roomCodeExpired.page"));
const OverallResults = lazy(() => import("./pages/overallResult/overallResult.page"));
const PassVideo = lazy(() => import("./pages/passVideo/passVideo.page"));
const FailVideo = lazy(() => import("./pages/failVideo/failVideo.page"));
const SomethingWentWrong = lazy(() => import("./pages/somethingWentWrong/somethingWentWrong.page"));
const GameCreater = lazy(() => import("./pages/gameCreater/gameCreater.page"));
const ObsPuzzleMouse = lazy(() => import("./components/observationPuzzleMouse/obsPuzzleMouse.component"));
const DataToSheet = lazy(() => import("./pages/databaseToSheet/dataToSheet.page"));
const HackingPuzzleLeft = lazy(() => import("./components/hackingPuzzles/hackingPuzzleLeft/Game"));
const HackingPuzzleRight = lazy(() => import("./components/hackingPuzzles/hackingPuzzleRight/Game"));

// TODO: replace with actual game type
const gameType = "witw";

function App() {
  return (
    <Router>
        <Suspense fallback={<Loading gameType={gameType} />}>
          <Switch>
          <Route path="/teamWaiting">
            <TeamWaiting />
          </Route>

          {/* room 1 */}

          <Route path="/gameIntro">
            <GameIntro />
          </Route>
          <Route path="/roomOneResult">
            <RoomOneResult />
          </Route>
          <Route path="/observationPuzzleInstuctions">
            <ObservationPuzzleInstruction />
          </Route>
          <Route path="/observationPuzzleImg">
            <ObsPuzzleMouse />
          </Route>
          <Route path="/nextPuzzleChoice">
            <NextPuzzleChoice />
          </Route>
          <Route path="/logicGrid1">
            <Puzzle1 />
          </Route>
          <Route path="/logicGrid2">
            <Puzzle2 />
          </Route>
          <Route path="/logicGrid3">
            <Puzzle3 />
          </Route>
          <Route path="/ObservationPuzzle">
            <ObservationPuzzle />
          </Route>

          {/* room 2 */}

          <Route path="/room2Intro">
            <Room2Entry />
          </Route>
          <Route path="/room2">
            <Room2 />
          </Route>
          <Route path="/roomTwoResult">
            <RoomTwoResult />
          </Route>
          <Route path="/keypad">
            <KeypadPuzzle />
          </Route>
          <Route path="/hpl">
            <HackingPuzzleLeft />
          </Route>
          <Route path="/hpr">
            <HackingPuzzleRight />
          </Route>

          {/* room 3 */}

          <Route path="/room3Intro">
            <Room3Entry />
          </Route>
          <Route path="/guessit">
            <GeoGuesserGame />
          </Route>
          <Route path="/roomThreeResult">
            <RoomThreeResult />
          </Route>
          <Route path="/pv34124">
            <PassVideo />
          </Route>
          <Route path="/fv2315">
            <FailVideo />
          </Route>
          <Route path="/overallResults">
            <OverallResults />
          </Route>

          {/* infrastructure */}
          <Route path="/gameCreater">
            <GameCreater />
          </Route>
          <Route path="/fill-sheet">
            <DataToSheet />
          </Route>

          <Route path="/roomCodeExpired">
            <RoomCodeExpired />
          </Route>
          <Route path="/wardengameStart">
            <WardenGameStart />
          </Route>
          <Route path="/isa">
            <PlayerGameStart />
          </Route>
          <Route path="/waitingRoom">
            <WaitingRoom />
          </Route>
          <Route path="/error">
            <SomethingWentWrong />
          </Route>
          <Route path="/">
            <Spyislandanimation />
          </Route>
        </Switch>
        </Suspense>
      </Router>
  );
}

export default App;
