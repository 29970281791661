import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./errorHandler";
import reducer, { initialState } from "./stateManagement/reducer.state";
import { StateProvider } from "./stateManagement/StateProvider.state";
import { GoogleOAuthProvider } from "@react-oauth/google";
import setupInterceptors from "./services/setupInterceptors";
import { Config } from "./services/config";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={Config.REACT_APP_CLIENT_ID}>
        <StateProvider initialState={initialState} reducer={reducer}>
          <App />
        </StateProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

setupInterceptors();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
